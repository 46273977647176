
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as cw20ZMFcPuK3HYMeta } from "/home/runner/work/injective-explorer/injective-explorer/pages/account/[account]/index/cw20.vue?macro=true";
import { default as indexvJBcw5L9PtMeta } from "/home/runner/work/injective-explorer/injective-explorer/pages/account/[account]/index/index.vue?macro=true";
import { default as indexvXyVz5aRdoMeta } from "/home/runner/work/injective-explorer/injective-explorer/pages/account/[account]/index.vue?macro=true";
import { default as index4EMVM8dR1sMeta } from "/home/runner/work/injective-explorer/injective-explorer/pages/account/[account]/nft/index.vue?macro=true";
import { default as indexEUZSMbqG18Meta } from "/home/runner/work/injective-explorer/injective-explorer/pages/account/[account]/staking/index.vue?macro=true";
import { default as restakeDAGBdiLwMoMeta } from "/home/runner/work/injective-explorer/injective-explorer/pages/account/[account]/staking/restake.vue?macro=true";
import { default as unstake130gvLWMv2Meta } from "/home/runner/work/injective-explorer/injective-explorer/pages/account/[account]/staking/unstake.vue?macro=true";
import { default as stakingGwL6xUvCDPMeta } from "/home/runner/work/injective-explorer/injective-explorer/pages/account/[account]/staking.vue?macro=true";
import { default as transactionsjzG5jK9tBhMeta } from "/home/runner/work/injective-explorer/injective-explorer/pages/account/[account]/transactions.vue?macro=true";
import { default as _91account_93h20OclfX44Meta } from "/home/runner/work/injective-explorer/injective-explorer/pages/account/[account].vue?macro=true";
import { default as _91denom_93HRrpXuHFtdMeta } from "/home/runner/work/injective-explorer/injective-explorer/pages/asset/[denom].vue?macro=true";
import { default as indexhs0SzgvDW3Meta } from "/home/runner/work/injective-explorer/injective-explorer/pages/assets/index.vue?macro=true";
import { default as smart_45contractsvF84AH4K8aMeta } from "/home/runner/work/injective-explorer/injective-explorer/pages/assets/smart-contracts.vue?macro=true";
import { default as assets5JxONUhLURMeta } from "/home/runner/work/injective-explorer/injective-explorer/pages/assets.vue?macro=true";
import { default as _91block_93ND4OLRWCUwMeta } from "/home/runner/work/injective-explorer/injective-explorer/pages/block/[block].vue?macro=true";
import { default as blocksZwwtmm0olXMeta } from "/home/runner/work/injective-explorer/injective-explorer/pages/blocks.vue?macro=true";
import { default as _91code_93NM6XvLsoXVMeta } from "/home/runner/work/injective-explorer/injective-explorer/pages/code/[code].vue?macro=true";
import { default as community_45spend_45poolANt69RLQI6Meta } from "/home/runner/work/injective-explorer/injective-explorer/pages/community-spend-pool.vue?macro=true";
import { default as executecxS5edVMn9Meta } from "/home/runner/work/injective-explorer/injective-explorer/pages/contract/[contract]/action/execute.vue?macro=true";
import { default as indexWUxLosTXkfMeta } from "/home/runner/work/injective-explorer/injective-explorer/pages/contract/[contract]/action/index.vue?macro=true";
import { default as actionEUF3BY6ybaMeta } from "/home/runner/work/injective-explorer/injective-explorer/pages/contract/[contract]/action.vue?macro=true";
import { default as balancesVqR2wh4RcuMeta } from "/home/runner/work/injective-explorer/injective-explorer/pages/contract/[contract]/balances.vue?macro=true";
import { default as cw20balancesBvX8g4Hy1FMeta } from "/home/runner/work/injective-explorer/injective-explorer/pages/contract/[contract]/cw20balances.vue?macro=true";
import { default as indexCynXTUk3NKMeta } from "/home/runner/work/injective-explorer/injective-explorer/pages/contract/[contract]/index.vue?macro=true";
import { default as init9ehBsV5hSgMeta } from "/home/runner/work/injective-explorer/injective-explorer/pages/contract/[contract]/init.vue?macro=true";
import { default as token_45holdersYIa6oGcJMpMeta } from "/home/runner/work/injective-explorer/injective-explorer/pages/contract/[contract]/token-holders.vue?macro=true";
import { default as _91contract_93vTnbrrqMqsMeta } from "/home/runner/work/injective-explorer/injective-explorer/pages/contract/[contract].vue?macro=true";
import { default as env4srMXunChyMeta } from "/home/runner/work/injective-explorer/injective-explorer/pages/env.vue?macro=true";
import { default as indexlDRZUe1rLcMeta } from "/home/runner/work/injective-explorer/injective-explorer/pages/index.vue?macro=true";
import { default as maintenance46QiZtYHjXMeta } from "/home/runner/work/injective-explorer/injective-explorer/pages/maintenance.vue?macro=true";
import { default as _91market_93KuLIipol26Meta } from "/home/runner/work/injective-explorer/injective-explorer/pages/market/[market].vue?macro=true";
import { default as funding_45rate_45historyrEsfQCTqhyMeta } from "/home/runner/work/injective-explorer/injective-explorer/pages/markets/funding-rate-history.vue?macro=true";
import { default as indexD2h1ame7B6Meta } from "/home/runner/work/injective-explorer/injective-explorer/pages/markets/index.vue?macro=true";
import { default as realtime_45funding_45rateDQiAJpsMCEMeta } from "/home/runner/work/injective-explorer/injective-explorer/pages/markets/realtime-funding-rate.vue?macro=true";
import { default as trading_45ruleseT2u2LGpWiMeta } from "/home/runner/work/injective-explorer/injective-explorer/pages/markets/trading-rules.vue?macro=true";
import { default as marketsjMVx5f8CF2Meta } from "/home/runner/work/injective-explorer/injective-explorer/pages/markets.vue?macro=true";
import { default as indexd6LGJ4uMzZMeta } from "/home/runner/work/injective-explorer/injective-explorer/pages/nft/[id]/index.vue?macro=true";
import { default as not_45found4xgYmtLfJ3Meta } from "/home/runner/work/injective-explorer/injective-explorer/pages/not-found.vue?macro=true";
import { default as raw_45txhsZgX3k56WMeta } from "/home/runner/work/injective-explorer/injective-explorer/pages/raw-tx.vue?macro=true";
import { default as codeKqt82EsmgdMeta } from "/home/runner/work/injective-explorer/injective-explorer/pages/smart-contracts/code.vue?macro=true";
import { default as indexw38Nutw6msMeta } from "/home/runner/work/injective-explorer/injective-explorer/pages/smart-contracts/index.vue?macro=true";
import { default as smart_45contractsUN9Cm7hYWyMeta } from "/home/runner/work/injective-explorer/injective-explorer/pages/smart-contracts.vue?macro=true";
import { default as _91tradeId_932IZbZckUG3Meta } from "/home/runner/work/injective-explorer/injective-explorer/pages/trade-history/derivatives/[tradeId].vue?macro=true";
import { default as indexwUMH3BoS4wMeta } from "/home/runner/work/injective-explorer/injective-explorer/pages/trade-history/derivatives/index.vue?macro=true";
import { default as index5cBaxeZBEYMeta } from "/home/runner/work/injective-explorer/injective-explorer/pages/trade-history/index.vue?macro=true";
import { default as _91tradeId_93Il44fywLkaMeta } from "/home/runner/work/injective-explorer/injective-explorer/pages/trade-history/spot/[tradeId].vue?macro=true";
import { default as indexOtUprGVh8TMeta } from "/home/runner/work/injective-explorer/injective-explorer/pages/trade-history/spot/index.vue?macro=true";
import { default as trade_45historyITNpc6K06dMeta } from "/home/runner/work/injective-explorer/injective-explorer/pages/trade-history.vue?macro=true";
import { default as event_45logsJQMRIBwcOKMeta } from "/home/runner/work/injective-explorer/injective-explorer/pages/transaction/[transaction]/event-logs.vue?macro=true";
import { default as indexjAJ4CcZarrMeta } from "/home/runner/work/injective-explorer/injective-explorer/pages/transaction/[transaction]/index.vue?macro=true";
import { default as _91transaction_93vWk3ruUw4qMeta } from "/home/runner/work/injective-explorer/injective-explorer/pages/transaction/[transaction].vue?macro=true";
import { default as transactionsf9smiZHihmMeta } from "/home/runner/work/injective-explorer/injective-explorer/pages/transactions.vue?macro=true";
export default [
  {
    name: _91account_93h20OclfX44Meta?.name,
    path: "/account/:account()",
    meta: _91account_93h20OclfX44Meta || {},
    component: () => import("/home/runner/work/injective-explorer/injective-explorer/pages/account/[account].vue"),
    children: [
  {
    name: indexvXyVz5aRdoMeta?.name,
    path: "",
    component: () => import("/home/runner/work/injective-explorer/injective-explorer/pages/account/[account]/index.vue"),
    children: [
  {
    name: "account-account-index-cw20",
    path: "cw20",
    component: () => import("/home/runner/work/injective-explorer/injective-explorer/pages/account/[account]/index/cw20.vue")
  },
  {
    name: "account-account-index",
    path: "",
    component: () => import("/home/runner/work/injective-explorer/injective-explorer/pages/account/[account]/index/index.vue")
  }
]
  },
  {
    name: "account-account-nft",
    path: "nft",
    component: () => import("/home/runner/work/injective-explorer/injective-explorer/pages/account/[account]/nft/index.vue")
  },
  {
    name: stakingGwL6xUvCDPMeta?.name,
    path: "staking",
    component: () => import("/home/runner/work/injective-explorer/injective-explorer/pages/account/[account]/staking.vue"),
    children: [
  {
    name: "account-account-staking",
    path: "",
    component: () => import("/home/runner/work/injective-explorer/injective-explorer/pages/account/[account]/staking/index.vue")
  },
  {
    name: "account-account-staking-restake",
    path: "restake",
    component: () => import("/home/runner/work/injective-explorer/injective-explorer/pages/account/[account]/staking/restake.vue")
  },
  {
    name: "account-account-staking-unstake",
    path: "unstake",
    component: () => import("/home/runner/work/injective-explorer/injective-explorer/pages/account/[account]/staking/unstake.vue")
  }
]
  },
  {
    name: "account-account-transactions",
    path: "transactions",
    component: () => import("/home/runner/work/injective-explorer/injective-explorer/pages/account/[account]/transactions.vue")
  }
]
  },
  {
    name: "asset-denom",
    path: "/asset/:denom()",
    component: () => import("/home/runner/work/injective-explorer/injective-explorer/pages/asset/[denom].vue")
  },
  {
    name: assets5JxONUhLURMeta?.name,
    path: "/assets",
    component: () => import("/home/runner/work/injective-explorer/injective-explorer/pages/assets.vue"),
    children: [
  {
    name: "assets",
    path: "",
    component: () => import("/home/runner/work/injective-explorer/injective-explorer/pages/assets/index.vue")
  },
  {
    name: "assets-smart-contracts",
    path: "smart-contracts",
    component: () => import("/home/runner/work/injective-explorer/injective-explorer/pages/assets/smart-contracts.vue")
  }
]
  },
  {
    name: "block-block",
    path: "/block/:block()",
    meta: _91block_93ND4OLRWCUwMeta || {},
    component: () => import("/home/runner/work/injective-explorer/injective-explorer/pages/block/[block].vue")
  },
  {
    name: "blocks",
    path: "/blocks",
    component: () => import("/home/runner/work/injective-explorer/injective-explorer/pages/blocks.vue")
  },
  {
    name: "code-code",
    path: "/code/:code()",
    meta: _91code_93NM6XvLsoXVMeta || {},
    component: () => import("/home/runner/work/injective-explorer/injective-explorer/pages/code/[code].vue")
  },
  {
    name: "community-spend-pool",
    path: "/community-spend-pool",
    component: () => import("/home/runner/work/injective-explorer/injective-explorer/pages/community-spend-pool.vue")
  },
  {
    name: _91contract_93vTnbrrqMqsMeta?.name,
    path: "/contract/:contract()",
    meta: _91contract_93vTnbrrqMqsMeta || {},
    component: () => import("/home/runner/work/injective-explorer/injective-explorer/pages/contract/[contract].vue"),
    children: [
  {
    name: actionEUF3BY6ybaMeta?.name,
    path: "action",
    component: () => import("/home/runner/work/injective-explorer/injective-explorer/pages/contract/[contract]/action.vue"),
    children: [
  {
    name: "contract-contract-action-execute",
    path: "execute",
    component: () => import("/home/runner/work/injective-explorer/injective-explorer/pages/contract/[contract]/action/execute.vue")
  },
  {
    name: "contract-contract-action",
    path: "",
    component: () => import("/home/runner/work/injective-explorer/injective-explorer/pages/contract/[contract]/action/index.vue")
  }
]
  },
  {
    name: "contract-contract-balances",
    path: "balances",
    component: () => import("/home/runner/work/injective-explorer/injective-explorer/pages/contract/[contract]/balances.vue")
  },
  {
    name: "contract-contract-cw20balances",
    path: "cw20balances",
    component: () => import("/home/runner/work/injective-explorer/injective-explorer/pages/contract/[contract]/cw20balances.vue")
  },
  {
    name: "contract-contract",
    path: "",
    meta: indexCynXTUk3NKMeta || {},
    component: () => import("/home/runner/work/injective-explorer/injective-explorer/pages/contract/[contract]/index.vue")
  },
  {
    name: "contract-contract-init",
    path: "init",
    component: () => import("/home/runner/work/injective-explorer/injective-explorer/pages/contract/[contract]/init.vue")
  },
  {
    name: "contract-contract-token-holders",
    path: "token-holders",
    component: () => import("/home/runner/work/injective-explorer/injective-explorer/pages/contract/[contract]/token-holders.vue")
  }
]
  },
  {
    name: "env",
    path: "/env",
    meta: env4srMXunChyMeta || {},
    component: () => import("/home/runner/work/injective-explorer/injective-explorer/pages/env.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/home/runner/work/injective-explorer/injective-explorer/pages/index.vue")
  },
  {
    name: "maintenance",
    path: "/maintenance",
    meta: maintenance46QiZtYHjXMeta || {},
    component: () => import("/home/runner/work/injective-explorer/injective-explorer/pages/maintenance.vue")
  },
  {
    name: "market-market",
    path: "/market/:market()",
    component: () => import("/home/runner/work/injective-explorer/injective-explorer/pages/market/[market].vue")
  },
  {
    name: marketsjMVx5f8CF2Meta?.name,
    path: "/markets",
    component: () => import("/home/runner/work/injective-explorer/injective-explorer/pages/markets.vue"),
    children: [
  {
    name: "markets-funding-rate-history",
    path: "funding-rate-history",
    component: () => import("/home/runner/work/injective-explorer/injective-explorer/pages/markets/funding-rate-history.vue")
  },
  {
    name: "markets",
    path: "",
    component: () => import("/home/runner/work/injective-explorer/injective-explorer/pages/markets/index.vue")
  },
  {
    name: "markets-realtime-funding-rate",
    path: "realtime-funding-rate",
    component: () => import("/home/runner/work/injective-explorer/injective-explorer/pages/markets/realtime-funding-rate.vue")
  },
  {
    name: "markets-trading-rules",
    path: "trading-rules",
    component: () => import("/home/runner/work/injective-explorer/injective-explorer/pages/markets/trading-rules.vue")
  }
]
  },
  {
    name: "nft-id",
    path: "/nft/:id()",
    component: () => import("/home/runner/work/injective-explorer/injective-explorer/pages/nft/[id]/index.vue")
  },
  {
    name: "not-found",
    path: "/not-found",
    component: () => import("/home/runner/work/injective-explorer/injective-explorer/pages/not-found.vue")
  },
  {
    name: "raw-tx",
    path: "/raw-tx",
    component: () => import("/home/runner/work/injective-explorer/injective-explorer/pages/raw-tx.vue")
  },
  {
    name: smart_45contractsUN9Cm7hYWyMeta?.name,
    path: "/smart-contracts",
    component: () => import("/home/runner/work/injective-explorer/injective-explorer/pages/smart-contracts.vue"),
    children: [
  {
    name: "smart-contracts-code",
    path: "code",
    component: () => import("/home/runner/work/injective-explorer/injective-explorer/pages/smart-contracts/code.vue")
  },
  {
    name: "smart-contracts",
    path: "",
    component: () => import("/home/runner/work/injective-explorer/injective-explorer/pages/smart-contracts/index.vue")
  }
]
  },
  {
    name: trade_45historyITNpc6K06dMeta?.name,
    path: "/trade-history",
    component: () => import("/home/runner/work/injective-explorer/injective-explorer/pages/trade-history.vue"),
    children: [
  {
    name: "trade-history-derivatives-tradeId",
    path: "derivatives/:tradeId()",
    component: () => import("/home/runner/work/injective-explorer/injective-explorer/pages/trade-history/derivatives/[tradeId].vue")
  },
  {
    name: "trade-history-derivatives",
    path: "derivatives",
    component: () => import("/home/runner/work/injective-explorer/injective-explorer/pages/trade-history/derivatives/index.vue")
  },
  {
    name: "trade-history",
    path: "",
    meta: index5cBaxeZBEYMeta || {},
    component: () => import("/home/runner/work/injective-explorer/injective-explorer/pages/trade-history/index.vue")
  },
  {
    name: "trade-history-spot-tradeId",
    path: "spot/:tradeId()",
    component: () => import("/home/runner/work/injective-explorer/injective-explorer/pages/trade-history/spot/[tradeId].vue")
  },
  {
    name: "trade-history-spot",
    path: "spot",
    component: () => import("/home/runner/work/injective-explorer/injective-explorer/pages/trade-history/spot/index.vue")
  }
]
  },
  {
    name: _91transaction_93vWk3ruUw4qMeta?.name,
    path: "/transaction/:transaction()",
    meta: _91transaction_93vWk3ruUw4qMeta || {},
    component: () => import("/home/runner/work/injective-explorer/injective-explorer/pages/transaction/[transaction].vue"),
    children: [
  {
    name: "transaction-transaction-event-logs",
    path: "event-logs",
    component: () => import("/home/runner/work/injective-explorer/injective-explorer/pages/transaction/[transaction]/event-logs.vue")
  },
  {
    name: "transaction-transaction",
    path: "",
    component: () => import("/home/runner/work/injective-explorer/injective-explorer/pages/transaction/[transaction]/index.vue")
  }
]
  },
  {
    name: "transactions",
    path: "/transactions",
    component: () => import("/home/runner/work/injective-explorer/injective-explorer/pages/transactions.vue")
  }
]